import ProjectsComp from "../Elements/ProjectsComp";

function Projects(){
    return(
        <div className="container flexHeight">
            <ProjectsComp/>
        </div>
    )
}

export default Projects;