
import Form from '../Elements/Form'



function Contact() {
    return (
        
        <div className='flexHeight'>
        <Form></Form>
        </div>
       
    );
}

export default Contact;